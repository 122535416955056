import React, { useState } from "react";
import styles from "./LandingPageProgram.module.scss";
import { useSearchParams } from "react-router-dom";
import Register from "../Register";
import { gql, useLazyQuery } from "@apollo/client";
import { useEffect } from "react";
import { LIVE_URL } from "../../helpers/constants";
import ReadMoreButton from "../ReadMoreButton";
import { convertDate } from "../../helpers/utils";

const LandingPageProgram = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [formDetails, setFormDetails] = useState(null);
  const [isClosed, setClosed] = useState(false);

  const [applicationDetails, { data }] = useLazyQuery(GET_FORM);

  const applicationCode = searchParams.get("applicationCode");
  const currentDate = new Date();

  function getAdmissionForm() {
    applicationDetails({
      variables: {
        applicationCode: applicationCode,
      },
      context: {
        clientName: "admission",
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_AUTHTOKEN}`,
        },
      },
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      notifyOnNetworkStatusChange: true,
    }).then((res) => {
      console.log(res.data);
    });
  }

  useEffect(() => {
    if (data) {
      setFormDetails(data?.ApplicationWithParam);
      const endDate = data?.ApplicationWithParam?.closeDate;
      const currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0);
      const closed = currentDate >= new Date(+endDate);
      setClosed(closed);
    }
  }, [data]);

  useEffect(() => {
    if (applicationCode) {
      getAdmissionForm();
    }
  }, [applicationCode]);

  return (
    <div>
      <section className={styles.rankingSecMain}>
        <div className="container">
          <div className="row">
            <div className="col-xl-12 ">
              <div className="row">
                <div
                  className={`col-xl-7 d-md-block d-xl-flex justify-content-center align-items-center ${styles.WhiteBox}`}
                >
                  {formDetails && (
                    <div className={styles.contactTxtDiv}>
                      <div>
                        <img
                          src="/assets/images/xlri-og-image-new.png"
                          style={{ width: "200px" }}
                        />
                        <h3>
                          {formDetails?.programOfferDetails?.progName}(
                          {formDetails?.programOfferDetails?.programCode})
                          {isClosed ? (
                            <span style={{ color: "red" }}>
                              - Admission Closed
                            </span>
                          ) : (
                            ""
                          )}
                        </h3>
                        <ReadMoreButton>
                          {formDetails?.programOfferDetails?.description}
                        </ReadMoreButton>
                        <div className="mt-4">
                          <p>
                            Application Start Date:
                            {`${convertDate(
                              new Date(
                                +formDetails?.applicationDate
                              ).toISOString()
                            )}, ${new Date(
                              +formDetails?.applicationDate
                            ).getFullYear()}`}
                          </p>
                          <p>
                            Application End Date:
                            {`${convertDate(
                              new Date(+formDetails?.closeDate).toISOString()
                            )}, ${new Date(
                              +formDetails?.closeDate
                            ).getFullYear()}`}
                          </p>
                        </div>

                        <ul className={styles.btnWrapper}>
                          <li>
                            {formDetails ? (
                              <button
                                className={styles.applyBtnmain}
                                onClick={() => window.open(LIVE_URL, "_blank")}
                              >
                                Login Now
                                <img
                                  src="/assets/images/white-arrow-new.svg"
                                  alt=""
                                />
                              </button>
                            ) : (
                              <></>
                            )}
                          </li>
                        </ul>
                      </div>
                    </div>
                  )}
                </div>

                {formDetails && (
                  <>
                    {isClosed ? (
                      <>
                        {formDetails?.dynamicFormId ? (
                          <div className={`col-xl-5 ${styles.WhiteBox}`}>
                            <iframe
                              style={{
                                width: "100%",
                                height: "100%",
                                borderRadius: "5px",
                              }}
                              src={`${window.location.origin}/public-form?formId=${formDetails?.dynamicFormId}`}
                              frameborder="0"
                            ></iframe>
                          </div>
                        ) : (
                          <></>
                        )}
                      </>
                    ) : (
                      <div className={`col-xl-5 ${styles.WhiteBox}`}>
                        <Register isClosed={isClosed} />
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default LandingPageProgram;

const GET_FORM = gql`
  query ApplicationWithParam($applicationCode: String!) {
    ApplicationWithParam(applicationCode: $applicationCode) {
      _id
      program
      programOffer
      dynamicFormId
      programOfferDetails {
        _id
        progId
        programCode
        instituteId
        progName
        description
        progType
        category
        startDate
        endDate
        state
        status
      }
      batchCode
      applicationCode
      applicationDate
      description
      closeDate
      formName
      status
    }
  }
`;
